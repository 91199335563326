import revive_payload_client_89p79RBPCUN8jSF5KY_ZqYQMS5Jkb1l_wMFyyawjqKQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_magicast_365b05248ced33972b3b2c46b1d35f8e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_14_glQzVJ5JFe_nItBWOr8g8jP_r5VjQyS4Q2RbbZHo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_magicast_365b05248ced33972b3b2c46b1d35f8e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_JQ_gsxUcDbJjWnb0PbEzRYK1LF9M6lEPCdUM_fXq4wo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_magicast_365b05248ced33972b3b2c46b1d35f8e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_TJBuJjhtA1_40kuDLRBnXBo_p_ZcUivlD_DY6ZS7aXo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.1.6_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_vO8s0Liw0vj7yw4TeXzTd45IYTbnpFoNo8WAgWzOtok from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_magicast_365b05248ced33972b3b2c46b1d35f8e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_MydcgzBZpiZiqg9KxbjUqrja7rvdvM_daXEDyyO_HJs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_magicast_365b05248ced33972b3b2c46b1d35f8e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_YwE73G5zsSc1PHYnlaWWDSxTdYpsviawkM7tblp7nok from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_magicast_365b05248ced33972b3b2c46b1d35f8e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4V6MjcTwjQ53NntS1zNGe9pMnlYQObSBbo_zEm2izZI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_magicast_365b05248ced33972b3b2c46b1d35f8e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_UGq4Ty9llav6T81PDabKpQVSL3u5o61aRk8pAUr1ST4 from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_mPXKANNJ39lthjC4dHJOW1zvy4CaHp89MuJ8Z2spl5o from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_magicast_365b05248ced33972b3b2c46b1d35f8e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_0Y40KRFShVAQKFtRdCqf_AGRwY6vu75idNqIwTOqfeI from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.2_@types+node@22.13.10_jiti@2.4.2_sass@1.55.0_af0b9b9d064404ec8cadba191a8b8852/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import preview_client_M4MFWlizSatyVIUowK4VJc1Ur_7uSp9S9M3HGzLMZzc from "/opt/buildhome/repo/node_modules/.pnpm/@nuxthq+studio@2.2.1_magicast@0.3.5/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import siteConfig_dsrMilOLfOM2CLRy1OOWJ9WoJvcaQY5aSVlklqe8s8M from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_QuSxBU_SVVDfkHTzzXaJcTACMwwRH9gWPu1zLdPOcgI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_vWlBgqKMj1WgkQsSJwx52xTBp_5lJpr8iHoo3FV92k4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import _0_routeRules_K0LXX3K4pqPsK9KeIYNEnd0ietrbpvd6FcKNj0X0exI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import defaults_aMJANx05_4N9jeeISdeVOwxNLCnpv_Lgwk6ujewrhv8 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_89p79RBPCUN8jSF5KY_ZqYQMS5Jkb1l_wMFyyawjqKQ,
  unhead_14_glQzVJ5JFe_nItBWOr8g8jP_r5VjQyS4Q2RbbZHo,
  router_JQ_gsxUcDbJjWnb0PbEzRYK1LF9M6lEPCdUM_fXq4wo,
  _0_siteConfig_TJBuJjhtA1_40kuDLRBnXBo_p_ZcUivlD_DY6ZS7aXo,
  payload_client_vO8s0Liw0vj7yw4TeXzTd45IYTbnpFoNo8WAgWzOtok,
  navigation_repaint_client_MydcgzBZpiZiqg9KxbjUqrja7rvdvM_daXEDyyO_HJs,
  check_outdated_build_client_YwE73G5zsSc1PHYnlaWWDSxTdYpsviawkM7tblp7nok,
  chunk_reload_client_4V6MjcTwjQ53NntS1zNGe9pMnlYQObSBbo_zEm2izZI,
  plugin_UGq4Ty9llav6T81PDabKpQVSL3u5o61aRk8pAUr1ST4,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_mPXKANNJ39lthjC4dHJOW1zvy4CaHp89MuJ8Z2spl5o,
  plugin_0Y40KRFShVAQKFtRdCqf_AGRwY6vu75idNqIwTOqfeI,
  preview_client_M4MFWlizSatyVIUowK4VJc1Ur_7uSp9S9M3HGzLMZzc,
  siteConfig_dsrMilOLfOM2CLRy1OOWJ9WoJvcaQY5aSVlklqe8s8M,
  inferSeoMetaPlugin_QuSxBU_SVVDfkHTzzXaJcTACMwwRH9gWPu1zLdPOcgI,
  titles_vWlBgqKMj1WgkQsSJwx52xTBp_5lJpr8iHoo3FV92k4,
  _0_routeRules_K0LXX3K4pqPsK9KeIYNEnd0ietrbpvd6FcKNj0X0exI,
  defaults_aMJANx05_4N9jeeISdeVOwxNLCnpv_Lgwk6ujewrhv8
]